import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{staticClass:"flex-grow-0",attrs:{"color":"primary","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_vm._v("Wewo")])],1),_c(VNavigationDrawer,{staticClass:"pt-4",attrs:{"temporary":"","absolute":"","left":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,_vm._l((_vm.groups),function(group,index){return _c(VListGroup,{key:index,on:{"click":function($event){return _vm.selectGroup(group)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(group.name))])],1)]},proxy:true}],null,true),model:{value:(group.active),callback:function ($$v) {_vm.$set(group, "active", $$v)},expression:"group.active"}},_vm._l((_vm.subgroups),function(subgroup,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.selectSubgroup(subgroup)}}},[_c(VListItemContent,[_c(VListItemTitle,{attrs:{"align":"right"}},[_vm._v(_vm._s(subgroup.name))])],1)],1)}),1)}),1)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }