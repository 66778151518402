<template>  
    <v-dialog persistent v-model="dialog" max-width="500">  
      <v-card>  
        <v-card-title class="headline">Enter Password</v-card-title>  
        <v-card-text>  
          <v-text-field @keyup.enter="submitPassword" v-model="passwordEntered" label="Password" type="password"></v-text-field>  
        </v-card-text>  
        <v-card-actions>  
          <v-btn color="primary" @click="submitPassword">Submit</v-btn>  
        </v-card-actions>  
      </v-card>  
    </v-dialog>  
  </template>  
    
  <script>  
  export default {  
    data() {  
      return {  
        dialog: true,  
        passwordEntered: '',
        passwordActual: process.env.VUE_APP_PASSWORD_ACTUAL  

      };  
    },  
    methods: {  
      submitPassword() {  
        if (this.passwordEntered === this.passwordActual) {  
          this.dialog = false; // Close dialog if password is correct  
          this.$emit('password-verified'); // Emit event to parent component  
        } else {  
          this.password = ''; // Clear password field if password is incorrect  
          alert('Incorrect password. Please try again.'); // Display error message to user  
        }  
      },  
    },  
  };  
  </script>  
  