import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Exer1Gen from "@/components/Exer1Gen.vue"
import Exer1Rev from '@/components/Exer1Rev.vue'
import Exer2Gen from '@/components/Exer2Gen.vue'
import Exer2Rev from '@/components/Exer2Rev.vue'
import Exer3Gen from '@/components/Exer3Gen.vue'
import Exer3Rev from '@/components/Exer3Rev.vue'
// import auth from '@/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // meta: { requiresAuth: true }

  },
  {
    path: '/exer1gen',
    name: 'exer1gen',
    component: Exer1Gen,
    // meta: { requiresAuth: true }
  },
  {
    path: '/exer1rev',
    name: 'exer1rev',
    component: Exer1Rev,
    // meta: { requiresAuth: true }
  },
  {
    path: '/exer2gen',
    name: 'exer2gen',
    component: Exer2Gen,
    // meta: { requiresAuth: true }
  },
  {
    path: '/exer2rev',
    name: 'exer2rev',
    component: Exer2Rev,
    // meta: { requiresAuth: true }
  },
  {
    path: '/exer3gen',
    name: 'exer3gen',
    component: Exer3Gen,
  },
  {
    path: '/exer3rev',
    name: 'exer3rev',
    component: Exer3Rev,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(async (to, from, next) => {  
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);  
  
//   if (requiresAuth) {  
//     try {  
//       const token = await auth.getToken();  
//       next();  
//     } catch (error) {  
//       auth.login();  
//     }  
//   } else {  
//     next();  
//   }  
// });  

export default router
