<template>  
  <div>
    <password-dialog @password-verified="openPage"></password-dialog>
    <div>  
      <v-overlay v-model="showProgress" class="justify-center align-center">
          <v-progress-circular
            indeterminate
            size="60"
            width="5"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      <v-row align="center" justify="center">  
        <v-col cols="12" md="4">  
          <v-img :src="currentImage" height="400px" contain></v-img>  
          <v-card-title class="text-center">            
          </v-card-title> 
          <v-row no-gutters align="center" justify="center" v-if="tblItems.length > 1">
            <v-col cols="6">
              <v-text-field label="Level" outlined dense v-model="currentImageLevel"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" justify="center" v-if="tblItems.length > 1">
            <v-col>
              <v-textarea label="Description" outlined dense v-model="currentImageDescription" rows="2" auto-grow></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters align="center" justify="center" v-if="tblItems.length > 1">
            <v-col>
              <v-text-field label="What should happen next?" outlined dense v-model="currentImageAnswer"></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="tblItems.length > 1">  
            <v-btn color="primary" @click="prevImage" :disabled="currentIndex === 0">Prev</v-btn>  
            <v-btn color="primary" class="ml-5" @click="nextImage" :disabled="currentIndex === tblItems.length - 1">Next</v-btn>
          </v-row>
          <v-row class="mt-10" justify="center" v-if="tblItems.length > 1">
            <v-btn color="red" @click="deleteData">Del</v-btn>
            <!-- <v-btn color="yellow" @click="updateData">Upd</v-btn>   -->
          </v-row> 
        </v-col>  
        <!-- Chat Column. -->
        <v-col cols="12" md="4">
          <v-row no-gutters justify="center">
            <v-col>
              <v-textarea outlined label="System Message" v-model="systemMsg" rows="2" auto-grow></v-textarea>
            </v-col>
          </v-row>
          <v-row>
          <v-col>
            <v-responsive v-if="activeChat" height="auto">
              <v-card flat>
                <v-card-text class="flex-grow-1 overflow-y-auto">
                  <template v-for="(msg) in messagesAll">
                    <div :class="{ 'd-flex flex-row-reverse': msg.me }">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-hover v-slot:default="{ hover }">
                            <v-chip
                              :color="msg.me ? 'orange darken-3' : 'blue darken-3'"
                              dark
                              style="height:auto;white-space: normal;"
                              class="pa-4 mb-2">
                                {{ msg.content }}
                            </v-chip>
                          </v-hover>
                        </template>
                      </v-menu>
                    </div>
                  </template>
                </v-card-text>
                <v-card-text class="flex-shrink-1">
                    <v-text-field
                    v-model="messageForm.content"
                    label="Enter your response..."
                    type="text"
                    no-details
                    variant="outlined"
                    append-outer-icon="mdi-send"
                    @keyup.enter="callAzureOpenAI"
                    @click:append-outer="callAzureOpenAI"
                    hide-details
                  />
                </v-card-text>
              </v-card>
            </v-responsive>
          </v-col>
        </v-row>
          <v-row class="mt-10" justify="center">
            <v-btn color="red" @click="firstCall2OpenAI" v-if="showBtnChat">Chat</v-btn>
            <v-btn color="red" @click="resetChat" v-if="!showBtnChat">Reset</v-btn>
            <!-- <v-btn color="yellow" @click="updateData">Upd</v-btn>   -->
          </v-row> 
        </v-col>
      </v-row>  
    </div>  
  </div>
</template>  

<script>
import PasswordDialog from '@/views/PasswordDialog.vue'; 
import axios from 'axios';

  export default {
    components: {  
      PasswordDialog,  
    },  
    data: () => ({
      pageOpen: false,
      currentIndex: 0,  
      nextToken: null,
      morePages: true,
      // tblItems: [{id: 1, answer: 'A'}, {id: 2, answer: 'B'}],
      tblItems: [{id: "", level: "", imgID: "", saImageUrl: "", description: "", answer: "", deleteVote: 0, status: "", misc1: "", misc2: ""}],
      imageUrl: "",
      // imageUrl: "https://dalleproduse.blob.core.windows.net/private/images/695fc7d9-da6c-478c-bd31-7122d04e237f/generated_00.png?se=2024-03-23T14%3A18%3A09Z&sig=0OldlrLFcacP8IbJwh%2BUjcyW7GHLaPQtsKwnHa7f%2Fsc%3D&ske=2024-03-29T12%3A59%3A27Z&skoid=09ba021e-c417-441c-b203-c81e5dcd7b7f&sks=b&skt=2024-03-22T12%3A59%3A27Z&sktid=33e01921-4d64-4f8c-a055-5bdaffd5e33d&skv=2020-10-02&sp=r&spr=https&sr=b&sv=2020-10-02", 
      showProgress: false,

      // Azure Blob Storage Info
      // SAS Token at the Storage Account level; not container... allow to expire about 1 year
      accountName: "sandesa",
      sasToken: process.env.VUE_APP_SA_SASTOKEN,
      chatURL: process.env.VUE_APP_CHAT_URL,
      chatApiKey: process.env.VUE_APP_CHAT_APIKEY,
      first: 4,

      // Chat
      activeChat: 1,
      messagesAll: [],
      messageForm: {
        content: "",
        me: true
      },
      reqBody: {
        messages: [],
        temperature: 0.2,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },
      systemMsg: "You are a speech therapist. With sequencing events exercise, your goal is to help your patient figure out what should happen next. Ask exactly one phrase, 'What should happen next?'. No second chances. If my answer is wrong, tell me if I got it right or wrong then give me a one sentence response that includes the right answer. The right answer is ",
      showBtnChat: true
    }),
    mounted(){
      this.showProgress = true
      this.retrieveItems()
      // this.$refs.passwordDialog.dialog = true; // Open password dialog when component is mounted  
    },
    computed: {
      currentImage() {  
        return this.tblItems[this.currentIndex].saImageUrlwToken  
      },  
      currentImageAnswer: {  
        get() {  
          return this.tblItems[this.currentIndex].answer  
        },  
        set(value) {  
          this.tblItems[this.currentIndex].answer = value  
        }  
      },
      currentImageLevel: {  
        get() {  
          return this.tblItems[this.currentIndex].level  
        },  
        set(value) {  
          this.tblItems[this.currentIndex].level = value  
        }  
      },
      currentImageDescription: {  
        get() {  
          return this.tblItems[this.currentIndex].description  
        },  
        set(value) {  
          this.tblItems[this.currentIndex].description = value  
        }  
      }
    }, 
    methods: { 
      openPage() {  
        this.pageOpen = true; // Open protected page if password is correct  
      },  
      async updateData(id, level, imgID, saImageUrl, description, answer, deleteVote, status, misc1, misc2){
        // const id = "1711131159712"
        // const level = "1"
        // const imgID="1711131159712"
        // const saImageUrl="https://sandesa.blob.core.windows.net/sandesac/1711131159712.png?"
        // const description="Two matching cartoon images of houses, one with a red roof and the other with a green roof."
        // const answer="roof"
        // const deleteVote=0
        // const status=""
        // const misc1=""
        // const misc2=""

        const data = {
          id: id,
          level: level,
          imgID: imgID,
          saImageUrl: saImageUrl,
          description: description,
          answer: answer,
          deleteVote: deleteVote,
          status: status,
          misc1: misc1, 
          misc2: misc2
        };

        // console.log(data)

        const gql = `
          mutation update($id: ID!, $_partitionKeyValue: String!, $item: Updateexer2imageInput!) {
            updateexer2image(id: $id, _partitionKeyValue: $_partitionKeyValue, item: $item) {
              id
              level
              imgID
              saImageUrl
              description
              answer
              deleteVote
              status
              misc1
              misc2
            }
          }`;

        const query = {
          query: gql,
          variables: {
            id: id,
            _partitionKeyValue: imgID,
            item: data
          } 
        };
        
        const endpoint = "/data-api/graphql";
        // const result = await fetch(endpoint, {

        try {
          await fetch(endpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(query)
          });

        } catch (error) {
          console.log(error);
        }
      },
      deleteData(){
        // 1 means delete item
        // R means reviewed

        this.updateData(
          this.tblItems[this.currentIndex].id, 
          this.tblItems[this.currentIndex].level, 
          this.tblItems[this.currentIndex].imgID,
          this.tblItems[this.currentIndex].saImageUrl,
          this.tblItems[this.currentIndex].description,
          this.tblItems[this.currentIndex].answer,
          1,
          // this.tblItems[this.currentIndex].status,
          "R",
          this.tblItems[this.currentIndex].misc1,
          this.tblItems[this.currentIndex].misc2
        )

        this.tblItems.splice(this.currentIndex,1)
      },
      resetChat(){
        this.messagesAll = []
        this.reqBody.messages = []
        this.firstCall2OpenAI()
      },
      nextImage() {
        this.updateData(
          this.tblItems[this.currentIndex].id, 
          this.tblItems[this.currentIndex].level, 
          this.tblItems[this.currentIndex].imgID,
          this.tblItems[this.currentIndex].saImageUrl,
          this.tblItems[this.currentIndex].description,
          this.tblItems[this.currentIndex].answer,
          this.tblItems[this.currentIndex].deleteVote,
          this.tblItems[this.currentIndex].status,
          //  "R",
          this.tblItems[this.currentIndex].misc1,
          this.tblItems[this.currentIndex].misc2
        )
        this.currentIndex++  
        this.messagesAll = []
        this.reqBody.messages = []
        this.showBtnChat = true
      },  
      prevImage() {  
        this.updateData(
          this.tblItems[this.currentIndex].id, 
          this.tblItems[this.currentIndex].level, 
          this.tblItems[this.currentIndex].imgID,
          this.tblItems[this.currentIndex].saImageUrl,
          this.tblItems[this.currentIndex].description,
          this.tblItems[this.currentIndex].answer,
          this.tblItems[this.currentIndex].deleteVote,
          this.tblItems[this.currentIndex].status,
          // "R",
          this.tblItems[this.currentIndex].misc1,
          this.tblItems[this.currentIndex].misc2
        )
        this.currentIndex--  
        this.messagesAll = []
        this.reqBody.messages = []
        this.showBtnChat = true
      },  
      async retrieveItems(){
        if (this.morePages === false){
          return
        }
        // Set initial variables  
        const variables = {  
          first: this.first,
          after: this.nextToken,
          filtervar: ""   
        };  
        const query = `
          query Exer2Images($first: Int!, $after: String, $filtervar: String){
            exer2images (first: $first, after: $after, filter: { status : { eq: $filtervar}}){
              items {
                id,
                level,
                imgID,
                saImageUrl,
                description,
                answer,
                deleteVote,
                status,
                misc1,
                misc2
              }
              endCursor
              hasNextPage
            }
          }`;
        const endpoint = "/data-api/graphql";

        try{
        const response = await fetch(endpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({query, variables})
        })
        const result = await response.json();
        const preTblItems = result.data.exer2images.items.map(item => ({
          id: item.id,
          level: item.level,
          imgID: item.imgID,
          saImageUrl: item.saImageUrl,
          saImageUrlwToken: item.saImageUrl + this.sasToken,
          description: item.description,
          answer: item.answer,
          deleteVote: item.deleteVote,
          status: item.status,
          misc1: item.misc1,
          misc2: item.misc2
        }))

        // If this is the first retrieval, remove the blank empty data
        if (this.tblItems.length < 2){
          this.tblItems = []
        }

        this.tblItems = this.tblItems.concat(preTblItems)

        this.morePages = result.data.exer2images.hasNextPage
        this.nextToken = result.data.exer2images.endCursor

        this.showProgress = false

        // console.log(this.nextToken)
        // console.log(this.morePages)
        // console.log(this.tblItems.length)
        // console.log(this.currentIndex)


        } catch (err) {
          console.log(err)
        }
      },
      postMessage(botContentMsg){
        this.messagesAll.push({content: this.messageForm.content, me: true}) //me
        this.messageForm.content = ""
        this.messagesAll.push({content: botContentMsg, me: false})
        // console.log(this.messagesAll)
      },
      async firstCall2OpenAI(){
        this.showBtnChat = false
        console.log(this.botContentMsg)
        console.log("here")
        this.reqBody.messages.push(
          {
            role: "system",
            content: this.systemMsg + this.currentImageAnswer 
          },      
          {
            role: "user",
            content: "ask"
          }
        )

        try {
          const res = await axios.post(
            this.chatURL,
            JSON.stringify(this.reqBody),
            {
              headers: {
                'content-type': 'application/json',
                'api-key': this.chatApiKey,
              },
            },
          );
          // this.chatMessages[this.botMessageArrayIndex].msg = res.data.choices[0].message.content;
          const botMsg = res.data.choices[0].message.content
          this.messagesAll.push({content: botMsg, me: false})
          this.reqBody.messages.push({role: "assistant", content: botMsg })
          this.showProgress = false
        } catch (error) {
          console.log(error);
          // console.log(error.response.data.error);
        }
      },
      async callAzureOpenAI() {
        this.showProgress = true
        // Execute when SEND is clicked

        this.reqBody.messages.push({role: "user", content: this.messageForm.content})
        console.log(this.reqBody.messages)

        try {
          const res = await axios.post(
            this.chatURL,
            JSON.stringify(this.reqBody),
            {
              headers: {
                'content-type': 'application/json',
                'api-key': this.chatApiKey,
              },
            },
          );
          // this.chatMessages[this.botMessageArrayIndex].msg = res.data.choices[0].message.content;
          this.postMessage(res.data.choices[0].message.content)
          this.showProgress = false
        } catch (error) {
          console.log(error);
          // console.log(error.response.data.error);
        }
      },
    },
    watch: {  
      currentIndex(newVal) {  
        if ((this.tblItems.length - newVal) < 3) {  
          this.retrieveItems();  
        }  
      },  
    },  
}
</script>
