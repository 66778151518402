<template>
  <div>
    <password-dialog @password-verified="openPage"></password-dialog>
    <v-container fluid>
      <v-overlay v-model="showProgress" class="justify-center align-center">
        <v-progress-circular
          indeterminate
          size="60"
          width="5"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col>
          <v-row align="center" justify="center">
            <h3 class="mt-10">What is Wrong?</h3>
          </v-row>
          <v-row no-gutters class="mt-10" align="center" justify="center">

            <v-col
              cols="12"
              md="10"
            >
              <v-textarea
                outlined
                label="Description (Generated Prompt)"
                v-model="botContentMsg"
                rows="3"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="center">
          <v-col v-if="showPromptBtn" cols="auto">  
            <v-btn
              color="orange"
              @click="genPrompt"
            >Prompt</v-btn>  
          </v-col>  
          <v-col v-if="showImageBtn" cols="auto">  
            <v-btn
              color="primary"
              @click="genImage"
            >Image</v-btn>  
          </v-col>
          <v-col
            v-if="showSaveBtn"
            class="d-flex"
            cols="12"
            md="2"
          >
            <v-select
              :items="itemLevels"
              label="Level"
              outlined
              v-model="level"
              dense
            ></v-select>
          </v-col>
          <v-col 
            v-if="showImageBtn"
            class="d-flex"
            cols="12"
            md="2"
          >
            <v-text-field
              label="Answer"
              outlined
              dense
              v-model="answer"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">  
            <v-btn
              color="green"
              @click="saveImage"
            >Save</v-btn>  
          </v-col>
          </v-row>
          <v-row align="center" justify="center">
          <v-col cols="auto">
            <v-img
              v-if = "imageUrl"
              :src="imageUrl"
              width="512"
              height="512"
            ></v-img>
          </v-col>
          </v-row>
        </v-col>
        <!-- Chat Column Kate -->
        <v-col cols="12" md="4">
          <v-row>
          <v-col>
            <v-responsive v-if="activeChat" height="auto">
              <v-card flat>
                <v-card-text class="flex-grow-1 overflow-y-auto">
                  <template v-for="(msg) in messagesAllChat">
                    <div :class="{ 'd-flex flex-row-reverse': msg.me }">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-hover v-slot:default="{ hover }">
                            <v-chip
                              :color="msg.me ? 'orange darken-3' : 'blue darken-3'"
                              dark
                              style="height:auto;white-space: normal;"
                              class="pa-4 mb-2">
                                {{ msg.content }}
                            </v-chip>
                          </v-hover>
                        </template>
                      </v-menu>
                    </div>
                  </template>
                </v-card-text>
                <v-card-text class="flex-shrink-1">
                    <v-text-field
                    v-model="messageForm.content"
                    label="Enter your response..."
                    type="text"
                    no-details
                    variant="outlined"
                    append-outer-icon="mdi-send"
                    @keyup.enter="callAzureOpenAI"
                    @click:append-outer="callAzureOpenAI"
                    hide-details
                  />
                </v-card-text>
              </v-card>
            </v-responsive>
          </v-col>
        </v-row>
          <v-row class="mt-10" justify="center">
            <v-btn color="red" @click="firstCall2OpenAI" v-if="showBtnChat">Chat</v-btn>
            <v-btn color="red" @click="resetChat" v-if="!showBtnChat">Reset</v-btn>
          </v-row> 
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
  import PasswordDialog from '@/views/PasswordDialog.vue'; 
  import axios from 'axios' 
  export default {
    name: 'HelloWorld',
    components: {  
      PasswordDialog,  
    },  
    data: () => ({
      pageOpen: false,
      systemMsg: "You are a creative therapist.",
      // promptMsg: "Provide a description of a non-fiction situation whereby there's something unusual or does not belong in 1 sentence.",
      // promptMsg: "Provide a description of an image whereby there's something wrong or does not belong in 1 sentence.",
      promptMsg: "Provide a description of an image whereby there's something unusual in the image in 1 sentence.",

      exampleMsg1: "A person in scuba gear reading book in a library",
      // exampleMsg2: "Two identical cartoon images of a boy, one holding one balloon and the other with two balloons.",
      // exampleMsg3: "Two identical cartoon images of a boy wearing shorts, one with socks and the other without.",
      // exampleMsg3: "Two identical cartoon images of cats, one with a pink collar and the other with a blue collar.",
      showPromptBtn: true,
      showImageBtn: true,
      showSaveBtn: false,

      // FOR PROMPT CREATION
      prePromptMsg: "There are two images hanging on a white wall using the same frame. ",
      activeChat: 1,
      botContentMsg: "",
      // botContentMsg: "",
      resFromBot: "",
      messagesAll: [],
      messageForm: {
        content: "",
        me: true
      },
      reqBody: {
        messages: [],
        temperature: 0.9,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },

      // FOR IMAGE CREATION
      reqBody4Img: {
        "model": "Dalle3",
        "prompt": "",
        "n": 1,
        "size": "1024x1024"
      },

      // FOR STORAGE AND DB
      imageUrl: "",
      showProgress: false,

      // SAVE IMAGE
      // Azure Blob Storage Info
      // SAS Token at the Storage Account level; not container... allow to expire about 1 year
      accountName: "sandesa",
      containerName: "sandesac3",
      sasToken: process.env.VUE_APP_SA_SASTOKEN,
      chatURL: process.env.VUE_APP_CHAT_URL,
      chatApiKey: process.env.VUE_APP_CHAT_APIKEY,
      dalle3URL: process.env.VUE_APP_DALLE3_URL,
      dalle3ApiKey: process.env.VUE_APP_DALLE3_APIKEY,
      answer: "",

      // COSMOS DB
      level: "2",
      itemLevels: ["1", "2", "3"],

      // CHAT
      activeChat: 1,
      messagesAllChat: [],
      messageForm: {
        content: "",
        me: true
      },
      systemMsgChat: " Ask me 'Can you spot what's wrong?'. After a wrong answer, provide the right answer; no second chance; do not ask anymore questions.",
      showBtnChat: true,
      reqBodyChat: {
        messages: [],
        temperature: 0.1,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },
    }),
    methods: {
      openPage() {  
        this.pageOpen = true; // Open protected page if password is correct  
      },  
      async genPrompt() {

        // if(this.reqBody.messages.length > 0){
        //           console.log(this.reqBody.messages[this.reqBody.messages.length -1].content)
        // this.reqBody.messages.forEach(item => console.log(item.content))
        // console.log("-----------------")
        // }



        this.showProgress = true
        // reqBody is all of the data that is being sent to AI
        // this.reqBody.messages.push({role: "user", content: this.messageForm.content})
        // console.log(this.reqBody.messages.length)
        if(this.reqBody.messages.length === 0){
          this.reqBody.messages.push({role: "system", content: this.systemMsg})
          this.reqBody.messages.push({role: "user", content: this.promptMsg})
          this.reqBody.messages.push({role: "assistant", content: this.exampleMsg1})
          // this.reqBody.messages.push({role: "assistant", content: this.exampleMsg2})
          // this.reqBody.messages.push({role: "assistant", content: this.exampleMsg3})
        } else {
          this.reqBody.messages.push({role: "user", content: "again"})
          this.reqBody.messages.push({role: "assistant", content: this.resFromBot})
        }


        // console.log(this.reqBody.messages.length)
        // console.log(this.reqBody)

        // console.log("--------------- Last Entry ---------------")
        // console.log(this.reqBody.messages[this.reqBody.messages.length -1].content)
        // console.log("--------------- Last Entry ---------------")

        // this.reqBody.messages.forEach(item => console.log(item.content))
        // console.log("******************   *********************")

        try {
          const res = await axios.post(
            this.chatURL,
            JSON.stringify(this.reqBody),
            {
              headers: {
                'content-type': 'application/json',
                'api-key': this.chatApiKey,
              },
            },
          );
          // botContentMsg is AI's response
          this.resFromBot = res.data.choices[0].message.content
          this.botContentMsg = this.resFromBot
          this.postMessage(this.botContentMsg)
          this.showImageBtn = true
        } catch (error) {
          console.log(error);
        }
        this.showProgress = false


      },  
      postMessage(botContentMsg){
        // messagesAll is what is shown in the User Interface
        this.messagesAll.push({content: this.messageForm.content, me: true}) //me
        this.messageForm.content = ""

        this.messagesAll.push({content: botContentMsg, me: false})
      },
      async genImage(){
        // this.reqBody.messages = []
        this.showProgress = true
        // console.log(this.botContentMsg)
        // this.showPromptBtn = false
        this.showSaveBtn = true
        this.reqBody4Img.prompt = this.botContentMsg
        try {
          const res = await axios.post(
            this.dalle3URL,
            JSON.stringify(this.reqBody4Img),
            {
              headers: {
                'content-type': 'application/json',
                'api-key': this.dalle3ApiKey,
              },
            },
          );

          this.imageUrl = res.data.data[0].url;
          // console.log(this.imageUrl)
        } catch (error) {
          console.log(error);
          // console.log(error.response.data.error);
        }
        this.showProgress = false
      },
      async saveImage() {  
        this.showProgress = true
        const uniqueName = `${Date.now()}`
        const blobName = uniqueName + ".png"
        const url = `https://${this.accountName}.blob.core.windows.net/${this.containerName}/${blobName}?${this.sasToken}`;
        // const imageBlob = await this.getImageBlob(this.imageUrl);  

        // Resize image
        // Set the width and height of the resized image  
        const width = 512;  
        const height = 512;  
          
        // Create a new Image object  
        const img = new Image();  
          
        // Set the source of the image  
        img.src = this.imageUrl;  
        img.setAttribute("crossorigin", "anonymous")
          
        // Wait for the image to load  
        img.onload = function() {  
          // Create a new canvas element  
          const canvas = document.createElement("canvas");  
          
          // Set the width and height of the canvas  
          canvas.width = width;  
          canvas.height = height;  
          
          // Draw the resized image onto the canvas  
          const ctx = canvas.getContext("2d"); 

          ctx.drawImage(img, 0, 0, width, height);  
          
          // Convert the canvas to a Blob object  
          canvas.toBlob(blob => {  

          // Save Resized Image to Azure Blob Storage
          try {
            axios.put(url, blob, {  
            // await axios.put(url, imageBlob, {  
              headers: {  
                "Content-Type": "image/jpeg",
                "x-ms-blob-type": "BlockBlob",
              },  
            });  
          } catch (error) {
            console.log(error);
          }
        }, "image/jpeg", 0.8);  
      };  

        // Save Resized Image to DB
        // const saImageUrl = `https://${this.accountName}.blob.core.windows.net/${this.containerName}/${blobName}?${this.sasToken}`;  
        const saImageUrl = `https://${this.accountName}.blob.core.windows.net/${this.containerName}/${blobName}?`;  
        const processedDesc = this.botContentMsg.substring(this.prePromptMsg.length)
        this.addData(uniqueName, saImageUrl, processedDesc, this.level, this.answer)
      },
      async addData(uniqueName, saImageUrl, description, level, answer){

        // console.log(uniqueName, saImageUrl, description)

        const data = {
          id: uniqueName,
          level: level,
          imgID: uniqueName,
          saImageUrl: saImageUrl,
          description: description,
          answer: answer,
          deleteVote: 0,
          status: "",
          misc1: "",
          misc2: ""
        };

        const gql1 = `
        mutation create($item: Createexer3imageInput!) {
            createexer3image(item: $item) {
              id
              level
              imgID
              saImageUrl
              description
              answer
              deleteVote
              status
              misc1
              misc2
            }
          }`;
        
        const query = {
          query: gql1,
          variables: {
            item: data
          } 
        };
        
        const endpoint = "/data-api/graphql";
        // const result = await fetch(endpoint, {

        try {
          await fetch(endpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(query)
          });

          this.showPromptBtn = true
            this.showImageBtn = true
            this.showSaveBtn = false
            this.imageUrl = ""
            // this.reqBody.messages = []
            // this.botContentMsg = ""
            this.resFromBot = ""
            this.level = ""
            this.answer = ""
            this.messagesAllChat = []
            this.reqBodyChat.messages = []

        } catch (error) {
          console.log(error);
        }


        this.showProgress = false
        // const response = await result.json();
        // console.table(response.data);
      },
      async getImageBlob(url) {  
        const response = await axios.get(url, {  
          responseType: "blob",  
        });  
        return response.data;  
      },
      async firstCall2OpenAI(){
        this.showBtnChat = false
        this.reqBodyChat.messages.push(
          {
            role: "system",
            content: this.botContentMsg + this.systemMsgChat
            // content: this.botContentMsg + this.systemMsgChat + "The right answer is " + this.answer 
          },      
          {
            role: "user",
            content: "ask"
          }
        )

        this.reqBodyChat.messages.forEach(item => console.log(item.content))
        console.log("******************   *********************")

        try {
          const res = await axios.post(
            this.chatURL,
            JSON.stringify(this.reqBodyChat),
            {
              headers: {
                'content-type': 'application/json',
                'api-key': this.chatApiKey,
              },
            },
          );
          // this.chatMessages[this.botMessageArrayIndex].msg = res.data.choices[0].message.content;
          const botMsg = res.data.choices[0].message.content
          this.messagesAllChat.push({content: botMsg, me: false})
          this.reqBodyChat.messages.push({role: "assistant", content: botMsg })
          this.showProgress = false
        } catch (error) {
          console.log(error);
          // console.log(error.response.data.error);
        }
      },
      async callAzureOpenAI() {
        this.showProgress = true
        // Execute when SEND is clicked

        this.reqBodyChat.messages.push({role: "user", content: this.messageForm.content})

        try {
          const res = await axios.post(
            this.chatURL,
            JSON.stringify(this.reqBodyChat),
            {
              headers: {
                'content-type': 'application/json',
                'api-key': this.chatApiKey,
              },
            },
          );
          // this.chatMessages[this.botMessageArrayIndex].msg = res.data.choices[0].message.content;
          this.postMessageChat(res.data.choices[0].message.content)
          this.showProgress = false
        } catch (error) {
          console.log(error);
          // console.log(error.response.data.error);
        }
      },
      postMessageChat(botContentMsgChat){
        this.messagesAllChat.push({content: this.messageForm.content, me: true}) //me
        this.messageForm.content = ""
        this.messagesAllChat.push({content: botContentMsgChat, me: false})
        // console.log(this.messagesAll)
      },
      resetChat(){
        this.messagesAllChat = []
        this.reqBodyChat.messages = []
        this.firstCall2OpenAI()
      },
  },  
}
</script>
