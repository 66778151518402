<template>  
  <v-app>  
    <v-app-bar color="primary" dark class="flex-grow-0">  
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Wewo</v-toolbar-title>  
    </v-app-bar>  

    <v-navigation-drawer v-model="drawer" temporary absolute left class="pt-4">  
      <v-list>  
        <v-list-group v-for="(group, index) in groups" :key="index" v-model="group.active" @click="selectGroup(group)">  
          <template v-slot:activator>  
            <v-list-item-content>  
              <v-list-item-title>{{ group.name }}</v-list-item-title>  
            </v-list-item-content>  
          </template>  
          <v-list-item v-for="(subgroup, index) in subgroups" :key="index" @click="selectSubgroup(subgroup)">  
            <v-list-item-content>  
              <v-list-item-title align="right">{{ subgroup.name }}</v-list-item-title>  
            </v-list-item-content>  
          </v-list-item>  
        </v-list-group>  
      </v-list>  
    </v-navigation-drawer>  

    <v-main>  
      <router-view/>
    </v-main>  
  </v-app>  
</template>  
  
<script>  
export default {  
  data() {  
    return {  
      drawer: false,   
      groups: [
        {name: "Exercise 1", label: "What is different?", code: "exer1", active: false },
        {name: "Exercise 2", label: "What comes next?", code: "exer2", active: false },
        {name: "Exercise 3", label: "What is wrong?", code: "exer3", active: false }
      ],
      subgroups: [
        { name: "Generate", code: "gen" },  
        { name: "Review", code: "rev" },  
        { name: "Problem", code: "prob"}  
      ],
      selectedSubgroup: {},  
      selectedGroup: {}
    };  
  },
  computed: {
    linkCalc(){
      return this.selectedGroup.code + this.selectedSubgroup.code
    }
  },
  methods: {  
    selectGroup(group){
      this.selectedGroup = group;
    },
    selectSubgroup(subgroup) {  
      this.drawer = false;  
      this.selectedSubgroup = subgroup;  
      this.$router.push("/" + this.linkCalc)
    },
  }  
};  
</script>  
